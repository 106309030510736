<template>
  <div class='relative' style='height: 700px; padding-bottom: 7rem; margin-bottom: 14rem;'>
    <content-background-cover
      background-color='#FAFAFA'
      class='z-0 hero-background'
      :top-offset='0'
      :height='heroBackgroundHeight'
      :style='heroBackgroundStyle' />
      <div 
        :class='heroContentClass'
        :style='heroContentCongressInfo.style'>
        <h3 class='text-lg font-semibold' v-html='heroContentCongressInfo.titleKorean'></h3>
        <h1 class='text-xl lg:text-4xl font-bold mb-4 lg:max-w-2xl' v-html='heroContentCongressInfo.titleEnglish'></h1>
        <h2 class='font-semibold' v-html='heroContentCongressInfo.subTitle'>Hybrid Congress</h2>
        <h3 class='font-semibold text-sm lg:text-base' v-html='heroContentCongressInfo.date'></h3>
        <h3 class='font-semibold text-sm lg:text-base' v-html='heroContentCongressInfo.venue'></h3>
        <h3 v-html='heroContentCongressInfo.webSite'></h3>
      </div>
      <section-links
         class='absolute left-0 w-full'
         style='bottom: -8rem;' 
        :section-links='sectionLinks' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import SectionLinks           from '@/components/SectionLinks.vue'


// used in KOA 2022 and APSR 2022
export default {
  name: 'MainHeroSection',
  components: {
    ContentBackgroundCover,
    SectionLinks,
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId'
    ]),
    heroBackgroundHeight () {
      if (this.showingEventId === 9) { //koa2022
        return 700
      } else if (this.showingEventId === 24) { //apsr2022
        return 900
      } else {
        return 700
      }
    },
    heroBackgroundStyle () {
      if (this.showingEventId === 9) { //koa2022
        return `background-image: url(https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/koa_main_bg_image_2.png); background-repeat: no-repeat; background-size: cover;`
      } else if (this.showingEventId === 24) { //apsr2022
        return `background-image: url(https://d3r2ol85dktaw0.cloudfront.net/conferences/APSR2022/visual_day.png); background-repeat: no-repeat; background-size: cover;  background-position: center;`
      } else {
        return []
      }
    },
    heroContentClass () {
      if (this.showingEventId === 9) { //koa2022
        return 'hero-content'
      } else if (this.showingEventId === 24) { //apsr2022
        return 'hero-content-apsr2022'
      } else {
        return ''
      }
    },
    heroContentCongressInfo () {
      if (this.showingEventId === 9) { //koa2022
        return {
          style: `color: #235099; background-color: #FFFFFF22; text-shadow: 1px 1px 1px #FFFFFF55;`,
          titleKorean: `2022 대한정형외과학회 제66차 국제학술대회`,
          titleEnglish: `The 66<span class='text-lg' style=' vertical-align: super'>th</span> Annual Congress of the
          Korean Orthopaedic Association 2022`,
          subTitle: `Hybrid Congress`,
          date: `October 13-15, 2022 <span class='uppercase font-normal opacity-70'>(THU - SAT)</span></h3>`,
          venue: `Swiss Grand Hotel Convention Center <span class='uppercase font-normal opacity-70'>(Seoul, South Korea)</span></h3>`,
          webSite: `<a class='font-normal text-sm lg:text-base underline' href='https://koa2022.koa.or.kr'>https://koa2022.koa.or.kr</a>`,
        }
      } else if (this.showingEventId === 24) { //apsr2022
        return {
          style: ``,
          titleKorean: ``,
          titleEnglish: `<img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/APSR2022/main_bg_image_letters_v2.png' class='h-40 lg:h-full' />`,
          subTitle: ``,
          date: ``,
          venue: ``,
          webSite: ``,
        }
      } else {
        return {}
      }
    },
    sectionLinks () {
      if (this.showingEventId === 9) { //koa2022
        return [
          {
            title:           'Photo Gallery',
            actionType:      'photo',
            actionString:    'visit gallery',
            linkOrUrl:       'PhotoGallery',
            queryParams:     {},
            color:           '#061138',
            backgroundColor: '#F2F3F7'
          },
          {
            title:           'Poster & Video',
            actionType:      'poster',
            actionString:    'Visit Site',
            linkOrUrl:       'Posters',
            queryParams:     {},
            color:           '#061138',
            backgroundColor: '#F2F3F7',
            subActions:         [
              { 
                id:              1,
                actionType:      'poster',
                actionString:    'Poster',
                linkOrUrl:       'Posters',
                queryParams:     {type: 'poster'},
              },
              {
                id:              2,
                actionType:      'video',
                actionString:    'Video',
                linkOrUrl:       'Posters',
                queryParams:     {type: 'video'},
              }
            ],
          },
          {
            title:           'Program at a glance',
            actionType:      'visit',
            actionString:    'Visit Site',
            linkOrUrl:       'Programs',
            queryParams:     {},
            color:           '#061138',
            backgroundColor: '#F2F3F7'
          },
          {
            title:           'Sponsorship',
            actionType:      'visit',
            actionString:    'Visit Site',
            linkOrUrl:       'Sponsorship',
            queryParams:     {},
            color:           '#061138',
            backgroundColor: '#F2F3F7'
          },
        ]
      } else if (this.showingEventId === 24) { //apsr2022
        return [
          {
            title:           'Programs (VOD)',
            actionType:      'more',
            actionString:    'More Details',
            linkOrUrl:       'Programs',
            queryParams:     {},
            color:           '#061138',
            backgroundColor: '#F2F3F7'
          },
          {
            title:           'Abstract',
            actionType:      'more',
            actionString:    'More Details',
            linkOrUrl:       'Abstracts',
            queryParams:     {},
            color:           '#061138',
            backgroundColor: '#F2F3F7'
          },
          {
            title:           'Photo Album',
            actionType:      'photo',
            actionString:    'View Photos',
            linkOrUrl:       'PhotoGallery',
            titleFontWeight: 'medium',
            queryParams:     {},
            color:           '#061138',
            backgroundColor: '#F2F3F7'
          },
          {
            title:           'Official Website',
            actionType:      'visit',
            actionString:    'Visit Site',
            linkOrUrl:       'http://apsr2022.org/',
            queryParams:     {},
            titleFontWeight: 'medium',
            color:           '#061138',
            backgroundColor: '#F2F3F7'
          },
        ]
      } else {
        return []
      }
    },
  }
}
</script>


<style lang='scss' scoped>
  .hero-content {
    @apply static py-16 px-8 text-left;
  }

  .hero-content h1 {
    line-height: auto;
  }

  .hero-background {
    background-position: 50% 80%;
  }
  
  @media (min-width: 1024px) { 
    .hero-content {
      @apply absolute top-0 right-0 py-16 rounded-xl text-right;
    }

    .hero-content h1 {
      line-height: 3rem;
    }

    .hero-background {
      background-position: 0% 50%;
    }
  }

  .hero-content-apsr2022 {
    @apply w-full flex flex-row justify-center items-center px-8 py-8;
  }

  .hero-content-apsr2022 h1 {
    line-height: auto;
  }

  @media (min-width: 1024px) { 
    .hero-content-apsr2022 {
      @apply px-8 pb-12 pt-32;
    }
    .hero-content-apsr2022 h1 {
      line-height: 3rem;
    }
  }
</style>