<template>
  <div class='section-link opacity-95 hover:opacity-100 cursor-pointer p-4 lg:p-8 flex flex-col justify-between items-start'
    :style='`background-color: ${backgroundColor}`'
    @click.stop='goToAction'>
    <h1 
      class='text-2xl lg:text-4xl mb-2 lg:mb-0 capitalize' 
      :class='sectionLinkFontWeight'
      :style='`color: ${sectionLink.color};`'>
      {{sectionLink.title}}
    </h1>
    <div v-if='hasSubActions'>
      <div v-for='subAction in sectionLink.subActions'
        :key='`subAction-${subAction.id}`'>
        <h3 class='uppercase flex flex-row justify-start items-center gap-x-2 mt-4'
          @click.stop='goToSubAction(subAction)'>
          <component :is='iconBySubActionType(subAction)' class='h-6' />
          {{subAction.actionString}}
        </h3>
      </div>
    </div>
    <h3 v-else 
      class='uppercase flex flex-row justify-start items-center gap-x-2'
      @click.stop='goToAction'>
      <component :is='iconByActionType' class='h-6' />
      {{sectionLink.actionString}}
    </h3>
  </div>
</template>

<script>
import { CloudDownloadIcon, CloudUploadIcon, EditIcon, WorldIcon, BookIcon, VideoIcon, SquarePlusIcon, PhotoIcon } from 'vue-tabler-icons'

export default {
  name: 'SectionLinksLinkBox',
  components: {
    CloudDownloadIcon, 
    CloudUploadIcon, 
    EditIcon,
    WorldIcon,
    BookIcon,
    VideoIcon,
    SquarePlusIcon,
    PhotoIcon,
  },
  props: [
    'sectionLink',
  ],
  computed: {
    hasSubActions () {
      return this.sectionLink.subActions && this.sectionLink.subActions.length > 0
    },
    iconByActionType () {
      switch (this.sectionLink.actionType) {
        case 'download':
          return 'cloud-download-icon'
        case 'upload':
          return 'cloud-download-icon'
        case 'edit':
          return 'edit-icon'
        case 'poster':
          return 'book-icon'
        case  'video':
          return 'video-icon'
        case  'more':
          return 'square-plus-icon'
        case 'photo':
          return 'photo-icon'
        case 'visit':
        default:
          return 'world-icon'
      }
    },
    backgroundColor () {
      return (this.sectionLink.backgroundColor) ? this.sectionLink.backgroundColor : this.sectionLink.color
    },
    sectionLinkFontWeight () {
      if (this.sectionLink.titleFontWeight) {
        return 'font-' + this.sectionLink.titleFontWeight.toLowerCase()
      } else if (this.sectionLink.actionType.toLowerCase() === 'more') {
        return 'font-medium'
      } else {
        return 'font-thin'
      }
    },
  },
  methods: {
    iconBySubActionType (subAction) {
      switch (subAction.actionType) {
        case 'download':
          return 'cloud-download-icon'
        case 'upload':
          return 'cloud-download-icon'
        case 'edit':
          return 'edit-icon'
        case 'poster':
          return 'book-icon'
        case  'video':
          return 'video-icon'
        case 'photo':
          return 'photo-icon'
        case 'visit':
        default:
          return 'world-icon'
      }
    },
    goToSubAction (subAction) {
      if (subAction.linkOrUrl.includes('http')) {
        window.open(subAction.linkOrUrl, '_blank')
      } else {
        this.$router.push({ name: subAction.linkOrUrl, query: subAction.queryParams })
      }
    },
    goToAction () {
      if (this.sectionLink.linkOrUrl.includes('http')) {
        window.open(this.sectionLink.linkOrUrl, '_blank')
      } else {
        this.$router.push({ name: this.sectionLink.linkOrUrl, query: this.sectionLink.queryParams })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
  .section-link {
    width: 100%;
    height: auto;
  }

  @media (min-width: 1024px) { 
    .section-link {
      width: 300px; 
      height: 260px;
    }
  }

</style>
