<template>
  <div class='flex flex-col lg:flex-row justify-center items-center px-2 lg:px-0 gap-y-2 lg:gap-y-0 lg:gap-x-2'>
    <section-links-link-box v-for='(sectionLink, index) in sectionLinks'
      :key='`section-link-${index}`'
      :section-link='sectionLink' />
  </div>
</template>

<script>
import SectionLinksLinkBox   from '@/components/SectionLinksLinkBox.vue'


export default {
  name: 'SectionLinks',
  components: {
    SectionLinksLinkBox,
  },
  props: [
    'sectionLinks',
  ],
}
</script>
